import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'


const hungerBookArray = [
  'Hunger_Book_1.jpg',
  'Hunger_Book_2.jpg',
  'Hunger_Book_3.jpg',
  'Hunger_Book_4.jpg',
  'Hunger_Book_5.jpg',
  'Hunger_Book_6.jpg',
  'Hunger_Book_7.jpg',
  'Hunger_Book_8.jpg',
]


const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const Hunger = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>Hunger</Title>
          <Description as="div">Exhibition and books, 2010</Description>
        </Container>
      </Header>
      <Content>
        <Figure 
          src='Hunger_1.jpg'
          caption='Sao Paulo, Brasil'
          marginTop='0'
        />
        <Text content='<i>Hunger</i> was Jens Assur’s biggest photographic project to date when it was launched in 2010. Five unique photo books were sent at three-week intervals to Sweden’s 1000 top opinion formers. Every book dealt with an important contemporary issue: the climate crisis, consumerism, hyper-urbanisation, human relationships with the soil and nature, rural areas with no life or people. When the fifth book was presented, an exhibition opened over an entire floor of Kulturhuset in Stockholm.' />
        <Figure 
          src='Hunger_2.jpg'
          caption='Pudong International Container Terminals, Shanghai, China'
        />
        <Figure 
          src='Hunger_3.jpg'
          caption='Dharavi, Mumbai, India'
        />
        <Text content='The intention behind this project was to create an insight into the rapidly growing threats to human living conditions and create debate on consumer culture with death cult elements. The first book in the series was entitled “If you love global warming – honk!!” In the preface, Jens Assur stated that he didn’t want Hunger to be perceived as a reportage or as art, but as reminders and follow-ups. To do the things that have to be done before it’s too late.' />
        <Figure 
          src='Hunger_4.jpg'
          caption='Copan Building, Sao Paulo, Brasil'
        />
        <Figure 
          src='Hunger_5.jpg'
          caption='Hollywood Hills, Los Angeles, USA'
        />
        <Figure 
          src='Hunger_6.jpg'
          caption='BLG Auto Terminal, Bremerhaven, Germany'
        />
        <Figure 
          src='Hunger_7.jpg'
          caption='Dubai, United Arab Emirates'
        />
        <CarouselWithArrows 
          images={hungerBookArray}
        />
      </Content>
    </>
  )
}

export default Hunger