import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import {useStaticQuery} from 'gatsby'
import Ticker from 'react-ticker'
import styled from '@emotion/styled' 
import PageVisibility from 'react-page-visibility'


// import {mapEdgesToNodes} from '../lib/helpers'

const NewsTicker = styled('div')`
  width: 100%;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  opacity: ${props =>
    props.tickerIsHidden ? '0' : '1' };

  .ticker {
    width: 100%;
  }
`

const TickerItem = styled('div')`
  margin-right: 1.5rem;
  display: flex;

  div {
    margin: 0 3px;
  }
`

/*
 * Query
 */
 /*
export const query = graphql`
  query NewsTickerQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
      edges {
        node {
          title
        }
      }
    }
  }
`
*/


const NewsTickerComponent = () => {
  const [tickerIsHidden, setTickerIsHidden] = useState(true)
  const [pageIsVisible, setPageIsVisible] = useState(true)


  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible)
  }

  // Hack for Safari
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))

    setTimeout(() =>{
      setTickerIsHidden(false)
    }, 500)
  },[])

 

  const { allSanityPost } = useStaticQuery(graphql`
    {
      allSanityPost(sort: {publishedAt: DESC}) {
        edges {
          node {
            title
            publishedAt(formatString: "YYYY.MM.DD")
          }
        }
      }
    }
  `)

  //const {data, errors} = props
  const newsItems = allSanityPost.edges

  // console.log(newsItems[0].node)

  // const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    /*
    <NewsTicker tickerIsHidden={tickerIsHidden} className="newsticker">
      <PageVisibility onChange={handleVisibilityChange}>
        {pageIsVisible && (
          <Ticker 
            speed={3}
            offset={30}
          >
            {({ index }) => (
              <>
                <TickerItem>
                  <div>{newsItems[index % newsItems.length].node.publishedAt}</div>
                  <div>{newsItems[index % newsItems.length].node.title}</div>
                </TickerItem>
              </>
            )}
          </Ticker>
        )}
      </PageVisibility>
    </NewsTicker>
    */
    
    
    <NewsTicker tickerIsHidden={tickerIsHidden} className="newsticker">

      <PageVisibility onChange={handleVisibilityChange}>
        { pageIsVisible && 
          <Ticker 
            speed={3}
            offset={30}
          >
            {
              ( {index} ) => (
                <TickerItem>
                  <div>{newsItems[index % newsItems.length].node.publishedAt}</div>
                  <div>{newsItems[index % newsItems.length].node.title}</div>
                </TickerItem>
              )
            } 
          </Ticker>
        }
      </PageVisibility>
    </NewsTicker>
      
  )
}
 
export default NewsTickerComponent