
import { createContext, createRef, useContext } from 'react';

const SiteContext = createContext();
const siteRef = createRef();

export const SiteProvider = ({ children }) => {
  return <SiteContext.Provider value={siteRef}>{children}</SiteContext.Provider>;
}

export function useSiteContext() {
  return useContext(SiteContext)
}