import React, { useCallback, useRef, useState, useEffect } from 'react'
import {useMeasure} from 'react-use-measure'
import Ticker from 'react-ticker'
// import { keyframes} from '@emotion/react'
import styled from '@emotion/styled'

import { motion, animate, useMotionValue, useTransform } from 'framer-motion'


/*
TODO: Implement scroller below


import React, { useCallback, useEffect, useState } from "react";
import { motion, animate, useMotionValue, useTransform } from "framer-motion";
import useMeasure from "react-use-measure";

import "./styles.css";

export default function App() {
  const [width, setWidth] = useState(175);

  const toggleWidth = useCallback(() => {
    setWidth((prev) => (prev === 175 ? 100 : 175));
  }, [setWidth]);

  const [containerRef, containerBounds] = useMeasure();
  const [titleRef, titleBounds] = useMeasure();

  const progress = useMotionValue(0);

  const x = useTransform(
    progress,
    [0, 1],
    [0, containerBounds.width - titleBounds.width]
  );

  useEffect(() => {
    const controls = animate(progress, [0, 1, 0], {
      repeat: Infinity,
      duration: 15,
      repeatDelay: 1
    });

    return controls.stop;
  }, [progress]);

  return (
    <div className="App">
      <div className="container" style={{ width: width }} ref={containerRef}>
        <motion.div style={{ position: "absolute", x }}>
          <div className="overflowingTitle" ref={titleRef}>
            A title that scrolls back and forth
          </div>
        </motion.div>
      </div>
      <button onClick={toggleWidth}>Toggle container width</button>
    </div>
  );
}





.App {
  font-family: sans-serif;
  text-align: center;
}

.container {
  border: 1px solid black;
  position: relative;
  width: 175px;
  height: 18px;
  overflow: hidden;
  transition: width 0.25s ease-in-out;
}

.overflowingTitle {
  white-space: nowrap;
}


*/



// Theme
import theme from '../utils/theme';

const Overflow = styled('div')`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis; // temp

  padding: 0px 11px;

  @media ${theme.breakpoints.md} { 
    padding: 0px 18px;
  }
`

const Title = styled('span')`
  // padding: 0 9px; temp
  /*
  opacity: ${props =>
    props.isOverflow ? '0' : '1' };
  */

  @media ${theme.breakpoints.md} { 
    // padding: 0 18px; temp
  }
`

const OverflowCheck = ({onChange, children, modalIsOpen}) => {
  const titleRef = useRef(null)
  const containerRef = useRef(null)
  
  const [titleWidth, setTitleWidth] = useState(null)
  const [containerWidth, setContainerWidth] = useState(null)
  const [isOverflow, setOverflowStatus] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      if(titleRef.current) {
        setTitleWidth(titleRef.current.offsetWidth)
        setContainerWidth(containerRef.current.clientWidth)

        // console.log(containerRef.current.clientWidth)
      }
    }, 1) // Hack so that Fields Notes button have time to appear if present for project.

  }, [children, modalIsOpen])

  useEffect(() => {
    const isOverflowed = titleWidth > containerWidth ? true : false

    setOverflowStatus(isOverflowed)
  }, [titleWidth, containerWidth])



  return (
    <Overflow 
      ref={containerRef}
      isOverflow={isOverflow}
      modalIsOpen={modalIsOpen}
    > 
      { /*
        isOverflow &&
        <motion.div
          style={{ 
            position: 'absolute',
            padding: '0 9px' 
          }}
          animate={{
            x: [0, containerWidth - titleWidth, 0],
          }}
          transition={{ repeat: Infinity, duration: 15, repeatDelay: 1 }}
        >
          {children}
        </motion.div>
      */ }

      <Title 
        ref={titleRef}
        //isOverflow={isOverflow}
      >
        {children}
      </Title>
    </Overflow>
  )
}

export default OverflowCheck


/*
const Container = styled('div')`
  position: relative;
  overflow: hidden;
`

const ScrollingTitle = styled('div')`
  white-space: nowrap;
`

const Title = () => {
  const [containerRef, containerBounds] = useMeasure()
  const [titleRef, titleBounds] = useMeasure()
  
  return (
    <Container ref={containerRef}>
      <motion.div style={{ position: "absolute", x }}>
        <Title ref={titleRef}>
          A title that scrolls back and forth
        </Title>
      </motion.div>
    </Container>
  )
}

export default Title
*/