const theme = {
  breakpoints: {
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
  },
  colors: {
    orange: '#E45A5A',
    white: '#FFFFFF',
    black: '#000000',
  }
};

export default theme