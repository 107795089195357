import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { use100vh } from 'react-div-100vh'

import theme from '../utils/theme';



const Scene = styled('section')`
  position: relative;
  z-index: 2;

  pointer-events: none;

  width: 100vw;
  /*
  height: ${props =>
    props.splash ? `${props.fullHeight}px` : `100px` };
  */
  height: ${props =>
    props.splash ? '100vh' : `0px` };

  transition: height 1.15s ease-in-out;
  background-color: #000;
`

const wave = keyframes`
  from, to {
    transform: translate3d(0,0,0);
  }

  35%, 55% {
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0, 1px, 0);
  }
`

const Flag = styled('div')`
  width: 100vw;
  height: ${props =>
    props.fullHeight ? `${props.fullHeight}px` : '100vh' };
  height: var(--real100vh);
  //height: 100vh;
  //height: -webkit-fill-available;
  
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${props =>
    props.splash && props.fullHeight ? '1' : '0' };

  transition: opacity 0.75s ease-in-out;

  svg {
    transform: translateY(-5%);
    height: auto;
    width: 90%;
    fill: #ffffff;
  }

  .flag-1 {
    animation: ${wave} 1.4s linear .1s infinite;
    transform-origin: center;
    will-change: transform;
  }

  .flag-2 {
    animation: ${wave} 1.4s linear .2s infinite;
    transform-origin: center;
    will-change: transform;
  }

  .flag-3 {
    animation: ${wave} 1.4s linear .3s infinite;
    transform-origin: center;
    will-change: transform;
  }

  .flag-4 {
    animation: ${wave} 1.4s linear .4s infinite;
    transform-origin: center;
    will-change: transform;
  }

  .flag-5 {
    animation: ${wave} 1.4s linear .5s infinite;
    transform-origin: center;
    will-change: transform;
  }

  .flag-6 {
    animation: ${wave} 1.4s linear .6s infinite;
    transform-origin: center;
    will-change: transform;
  }

  @media ${theme.breakpoints.md} { 
    svg {
      height: 60%;
      width: auto;
    }
  }
`

const Tagline = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 15px;

  pointer-events: none;

  color: #ffffff;

  /*
  opacity: ${props =>
    props.splash ? '0' : '1' };
  */
  opacity: ${props =>
    props.splash ? '0' : '0' };

  transition: opacity 0.6s ease-in-out 0.05s;

  font-size: 13px;
  line-height: 1.125;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;

  @media ${theme.breakpoints.md} { 
    font-size: 16px;
  }
`


const detectTransitionEnd = (event, setIntroFinished) => {
  if(event.propertyName !== 'height') return;
  
  setIntroFinished(true)
}


const IntroComponent = ({splash, setSplash, removeIntro, setRemoveIntro, setIntroFinished}) => {
  const fullHeight = use100vh()

  return (
    <Scene splash={splash} onTransitionEnd={(e)=> detectTransitionEnd(event, setIntroFinished)}>
      <Flag splash={splash} fullHeight={fullHeight}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386 450">
          <path id="Pole" d="M260 5.16h-3v115.7h3z"/>
          <path id="Circle" d="M193.5 447c-44.5 0-87.7-15.4-122.1-43.6-34.4-28.2-58-67.5-66.7-111.1-8.7-43.6-2-88.9 19-128.1 21-39.2 55-70 96.1-87 41.1-17 86.9-19.3 129.5-6.4S328.7 111 353.5 148c24.7 37 35.9 81.4 31.5 125.7-4.4 44.3-24 85.7-55.4 117.1-17.8 17.9-39 32.1-62.4 41.8-23.4 9.5-48.4 14.5-73.7 14.4zm0-381.6c-43.9 0-86.4 15.2-120.3 43-33.9 27.8-57.1 66.5-65.7 109.4S5.6 305.4 26.3 344c20.7 38.6 54.1 68.9 94.6 85.7 40.5 16.8 85.6 19 127.6 6.3s78.2-39.6 102.6-76c24.4-36.4 35.3-80.2 31-123.8-4.3-43.6-23.6-84.4-54.6-115.3-17.6-17.6-38.5-31.6-61.5-41.1-22.9-9.6-47.6-14.5-72.5-14.4z"/>

          <g>
            <path className="flag-5" id="R" d="M324.58 41h-6v12.6h2.58v-5h2.57a2.14 2.14 0 011.51.41 2.37 2.37 0 01.42 1.61v1.17a6.71 6.71 0 00.11 1.09c0 .16.1.39.18.67h2.92v-.32a1.18 1.18 0 01-.32-.31 1 1 0 01-.16-.42 6.51 6.51 0 01-.07-1.17v-.85a4.2 4.2 0 00-.37-2 2.45 2.45 0 00-1.24-1 2.54 2.54 0 001.47-1.22 3.87 3.87 0 00.23-3.1 4.4 4.4 0 00-.64-1.06 3.14 3.14 0 00-1.19-.88 5.62 5.62 0 00-2-.33zm-.58 2.17a2.61 2.61 0 011.23.22 1.64 1.64 0 01.59.66 1.67 1.67 0 01.14.88 1.46 1.46 0 01-.16.82 1.5 1.5 0 01-.57.6 3.11 3.11 0 01-1.28.21h-2.84v-3.39z"/>
            <path className="flag-4" id="U" d="M305.36 48.73a6.56 6.56 0 00.63 3.14c.78 1.36 2.26 2.05 4.45 2.05s3.66-.69 4.44-2.05a6.56 6.56 0 00.63-3.14V41h-2.69v7.75a4.4 4.4 0 01-.31 1.9 2 2 0 01-2.07 1.06 2.05 2.05 0 01-2.09-1.06 4.39 4.39 0 01-.3-1.9V41h-2.66z"/>
            <path className="flag-3" id="S" d="M292.61 49.7a3.92 3.92 0 00.34 1.72 3.75 3.75 0 001.05 1.37 6.2 6.2 0 004 1.13 5.59 5.59 0 003.78-1.15 3.62 3.62 0 001.34-2.86 3.06 3.06 0 00-.26-1.43 3 3 0 00-.87-1.16 5.68 5.68 0 00-2.2-.91l-2.19-.52a7.47 7.47 0 01-1.68-.53 1.07 1.07 0 01-.46-.41 1.15 1.15 0 01-.16-.6 1.33 1.33 0 01.16-.65 1.23 1.23 0 01.47-.49 3 3 0 011.67-.41 3.4 3.4 0 011.57.32 1.81 1.81 0 011 1.61h2.53a3.76 3.76 0 00-.36-1.71 3.83 3.83 0 00-1.1-1.36 5.63 5.63 0 00-3.45-1 5.24 5.24 0 00-3.66 1.12 3.6 3.6 0 00-.95 1.27 3.69 3.69 0 00-.31 1.54 3.08 3.08 0 00.28 1.53 3.15 3.15 0 001 1.2 8.55 8.55 0 002.77 1l1.37.3a6.34 6.34 0 011.75.59 1.1 1.1 0 01.42.41 1.22 1.22 0 01.14.56 1.46 1.46 0 01-.31.94 1.5 1.5 0 01-.83.53 5.34 5.34 0 01-1.54.2 2.92 2.92 0 01-1.21-.14 3 3 0 01-1-.64 2.51 2.51 0 01-.48-1.29z"/>
            <path className="flag-2" id="S-2" data-name="S" d="M280.33 49.67a3.71 3.71 0 001.43 3.09 6.19 6.19 0 003.93 1.13 5.65 5.65 0 003.78-1.14 3.69 3.69 0 001-1.29 3.62 3.62 0 00.35-1.59 3 3 0 00-.26-1.42 3 3 0 00-.87-1.15 5.53 5.53 0 00-2.2-.91l-2.2-.53a7.52 7.52 0 01-1.67-.52 1.08 1.08 0 01-.62-1 1.27 1.27 0 01.16-.66 1.35 1.35 0 01.46-.49 3 3 0 011.67-.41 3.4 3.4 0 011.57.32 1.71 1.71 0 01.75.66 1.76 1.76 0 01.28 1h2.53a3.7 3.7 0 00-1.42-3.12 5.55 5.55 0 00-3.45-1.06 5.28 5.28 0 00-3.67 1.12 3.54 3.54 0 00-.98 1.3 3.62 3.62 0 00-.32 1.55 3.1 3.1 0 00.28 1.53 3.15 3.15 0 001 1.2 8.53 8.53 0 002.77 1l1.36.3a5.48 5.48 0 011.76.6 1 1 0 01.41.4 1 1 0 01.14.56 1.46 1.46 0 01-.3.93 1.43 1.43 0 01-.83.53 4.48 4.48 0 01-1.54.21 2.76 2.76 0 01-1.21-.14 2.67 2.67 0 01-1-.64 2.4 2.4 0 01-.48-1.29z"/>
            <path className="flag-1" id="A" d="M267.15 53.59h2.76l.86-2.6h4.65l.84 2.6h2.86L274.65 41h-3zm6-9.73l1.59 5h-3.23z"/>
          </g>

          <g>
            <path className="flag-4" id="S-3" data-name="S" d="M302.9 32.18a3.66 3.66 0 00.34 1.72 3.71 3.71 0 001.09 1.37 6.22 6.22 0 003.92 1.13 5.63 5.63 0 003.75-1.17 3.56 3.56 0 001-1.23 3.63 3.63 0 00.34-1.58 3.16 3.16 0 00-1.13-2.59 5.84 5.84 0 00-2.2-.91l-2.19-.52a7 7 0 01-1.68-.53 1.07 1.07 0 01-.46-.41 1.15 1.15 0 01-.16-.6 1.36 1.36 0 01.16-.65 1.3 1.3 0 01.47-.49 3 3 0 011.67-.41 3.4 3.4 0 011.57.32 1.81 1.81 0 01.74.66 1.75 1.75 0 01.27 1h2.6a3.74 3.74 0 00-.37-1.71 3.64 3.64 0 00-1.1-1.36 5.66 5.66 0 00-3.45-1.06 5.36 5.36 0 00-3.66 1.12 3.62 3.62 0 00-1.28 2.72 3.12 3.12 0 00.28 1.53 3.06 3.06 0 001 1.2 8.47 8.47 0 002.77 1l1.37.3a6 6 0 011.74.58 1 1 0 01.41.41 1.09 1.09 0 01.15.56 1.48 1.48 0 01-1.13 1.42 5.09 5.09 0 01-1.55.21 2.8 2.8 0 01-1.22-.13 2.76 2.76 0 01-1-.63 2.49 2.49 0 01-.49-1.28z"/>
            <path className="flag-3" id="N" d="M290.24 36.07h2.49v-8.96l5.12 8.96h2.65V23.45h-2.47v8.78l-5.01-8.78h-2.78v12.62z"/>
            <path className="flag-2" id="E" d="M287.5 23.43h-9.27v12.61h9.57v-2.26h-6.99v-3.25h6.14v-2.2h-6.14v-2.64h6.69v-2.26z"/>
            <path className="flag-1" id="J" d="M267.15 31.28v.3a6.53 6.53 0 00.82 3.51c.55.86 1.62 1.28 3.23 1.28a3.52 3.52 0 003.33-1.58 5.25 5.25 0 00.54-2.56v-8.77h-2.64v8.85a3.58 3.58 0 01-.17 1.3 1.06 1.06 0 01-.47.51 1.32 1.32 0 01-1.84-.46 5.74 5.74 0 01-.31-2v-.3z"/>
          </g>

          <g>
            <path className="flag-6" id="O" d="M334.86 12.21A6.65 6.65 0 00332.94 7a6.52 6.52 0 00-8.27 0 6.65 6.65 0 00-1.92 5.2 6.68 6.68 0 001.92 5.21 6.55 6.55 0 008.27 0 6.82 6.82 0 001.92-5.2zm-6.05 4.45a3.23 3.23 0 01-1.4-.29 3.17 3.17 0 01-1.11-.88 5.18 5.18 0 01-.93-3.3 5.15 5.15 0 01.93-3.29 3.09 3.09 0 011.12-.9 3.57 3.57 0 012.77 0 3.06 3.06 0 011.11.88 5.16 5.16 0 01.92 3.28 5.2 5.2 0 01-.92 3.29 3.14 3.14 0 01-1.11.87 3 3 0 01-1.38.28z"/>
            <path className="flag-5" id="I" d="M317.62 5.9h2.62v12.64h-2.62z"/>
            <path className="flag-4" id="D" d="M310 5.9h-5.44v12.64H310a4.65 4.65 0 002.45-.56 4.59 4.59 0 001.79-1.76 8.35 8.35 0 001-4.26 9.18 9.18 0 00-.24-2.09 5.27 5.27 0 00-.9-2 4.14 4.14 0 00-2.1-1.64 6.88 6.88 0 00-2-.23zm-.44 2.25a2.56 2.56 0 012.41 1 6 6 0 01.6 2.93 6.55 6.55 0 01-.4 2.4 2.66 2.66 0 01-2.59 1.84h-2.43V8.11z"/>
            <path className="flag-3" id="U-2" data-name="U" d="M291.44 13.72a6.53 6.53 0 00.62 3.13q1.19 2.05 4.45 2t4.44-2a6.54 6.54 0 00.63-3.13v-7.8h-2.69v7.75a4.28 4.28 0 01-.31 1.9 2 2 0 01-2.07 1.06 2.06 2.06 0 01-2.09-1.06 4.41 4.41 0 01-.3-1.9V5.92h-2.68z"/>
            <path className="flag-2" id="T" d="M279.24 5.93v2.2h3.8v10.38h2.65V8.14h3.78V5.95l-10.23-.02z"/>
            <path className="flag-1" id="S-4" data-name="S" d="M267.48 14.64a3.69 3.69 0 00.36 1.73 3.6 3.6 0 001.16 1.37 6.2 6.2 0 003.91 1.12 5.61 5.61 0 003.75-1.14 3.65 3.65 0 001-1.28 3.5 3.5 0 00.34-1.59 3.05 3.05 0 00-.25-1.43 3.19 3.19 0 00-.88-1.15 5.7 5.7 0 00-2.2-.92l-2.2-.52a7.18 7.18 0 01-1.67-.52 1.07 1.07 0 01-.46-.41 1.15 1.15 0 01-.16-.6 1.33 1.33 0 01.16-.65 1.27 1.27 0 01.47-.48 3 3 0 011.66-.42 3.56 3.56 0 011.58.33 1.81 1.81 0 011 1.61h2.54a3.85 3.85 0 00-1.5-3.07 5.63 5.63 0 00-3.45-1A5.31 5.31 0 00269 6.69a3.62 3.62 0 00-1.3 2.81 3.13 3.13 0 00.3 1.5 3.06 3.06 0 001 1.2 8.72 8.72 0 002.76 1l1.37.3a6 6 0 011.75.59 1 1 0 01.42.41 1.08 1.08 0 01.14.56 1.46 1.46 0 01-.3.93 1.43 1.43 0 01-.83.53 4.51 4.51 0 01-1.55.21 2.76 2.76 0 01-1.21-.14 3 3 0 01-1-.64 2.53 2.53 0 01-.47-1.29z"/>
          </g>
        </svg>
      </Flag>

      <Tagline splash={splash}>
        Jens Assur is a Swedish Photographer,<br/>
        Director, Scriptwriter and Film Producer
      </Tagline>
    </Scene>
  )
}

export default IntroComponent