function set100vhVar() {
  // If less than most tablets, set CSS var to window height.
  let value100vh    = "100vh"
  let value99_5vh   = "99.5vh"
  let value90vh     = "90vh"
  let value2vh      = "2vh"

  if (window.innerWidth <= 1024) {
    value100vh      = `${window.innerHeight}px`
    value99_5vh     = `${(window.innerHeight / 100) * 99.5}px`
    value90vh       = `${(window.innerHeight / 100) * 90}px`
    value2vh        = `${(window.innerHeight / 100) * 2}px`
  }

  document.documentElement.style.setProperty("--real100vh", value100vh)
  document.documentElement.style.setProperty("--real99_5vh", value99_5vh)
  document.documentElement.style.setProperty("--real90vh", value90vh)
  document.documentElement.style.setProperty("--real2vh", value2vh)
}

export default set100vhVar