import React from 'react'
import HamburgerIcon from './hamburger'
import CrossIcon from './cross'
import FullScreenIcon from './fullscreen'

function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    case 'cross':
      return <CrossIcon />
    case 'fullscreen':
      return <FullScreenIcon />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
