import React from 'react'

// Components
import Text from '../components/text'
import FigureNotes from '../components/figureNotes'

const Notes = () => {
  
  return (
    <>
      <Text content='<p>I was in Rwanda at the time of the genocide, together with a reporter. We headed for a village where thousands of people had been murdered. The bodies were all over the place. We found out that there was one survivor from the village, so we went off to find him. Then he had to stand among the corpses and talk about the village. That was what journalists call the individual, unique angle. It was absolutely grotesque. We used him. As if what had happened in the village wasn’t enough.</p><p>That incident became the source for “Den Sista Hunden i Rwanda”. I filmed that with two of Sweden’s best actors, Reine Brynolfsson and Jonas Karlsson.</p>' />
      <FigureNotes 
        src='Last_Dog_Fieldnotes_1.jpg'
      />
      <Text content='<p>I’d always wanted to make films, and in the early 2000 that desire had turned into a strong longing. It also came about because I felt I’d reached the boundary in my documentary photography and was at risk of becoming a prisoner of my own form. When I attempted to join the film industry, I was advised to start off with shorts – and that advice was absolutely correct. I wanted to make films on the basis of the same criteria that I applied when I took my photos. To emphasise the important things. Not to get bogged down in formulae, but to find a new language for every tale. To own the idea myself. Sweden has a generous system for shorts which means that they don’t necessarily have to make back what it costs to make them. As a result, shorts are maybe the freest form.</p>' />
    </>
  )
}

export default Notes