import React from 'react'
import styled from '@emotion/styled'

import Image from '../components/image'
import Container from '../components/container'

import {Figcaption} from '../utils/styles'


const Figure = styled('figure')`
  position: relative;
  line-height: 0;
  margin: 0;
  margin-top: ${props =>
    props.marginTop ? `${props.marginTop}px` : '50px' };

  ${props => {
    if (props.blendMode == 'multiply') {
      return `
        mix-blend-mode: multiply;
    `
    }
  }}

  .gatsby-image-wrapper {
    box-shadow: ${props =>
      props.dropShadow ? '0px 0px 6px rgba(0, 0, 0, 0.25)' : 'none'};
  }

  .gatsby-image-wrapper img {
    transition: opacity .5s ease 0s!important;
  }
`

/*
const Figcaption = styled('figcaption')`
  font-family: Plantin;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0;
  position: absolute;
`
*/


const FigureComponent = ({src, caption, dropShadow, blendMode, marginTop}) => (
  <Container>
    <Figure dropShadow={dropShadow} blendMode={blendMode} marginTop={marginTop}>
      <Image filename={src} />

      {caption &&
        <Figcaption>{caption}</Figcaption>
      }
      
    </Figure>
  </Container>
)

export default FigureComponent