import React from 'react'
// import {css} from '@emotion/css'
import styled from '@emotion/styled'

import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'

import Container from '../components/container'


const Text = styled('div')`
  ${CommonTextStyles};
  margin: 45px 0 55px;
  //max-width: 920px;

  @media ${theme.breakpoints.lg} { 
    margin: 50px 0 60px;
    max-width: 83%;
  }
`


const TextComponent = ({content}) => (
  <Container>
    <Text dangerouslySetInnerHTML={{__html: content }} />
  </Container>
)

export default TextComponent