import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'



const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const LastDogInRwanda = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>The Last Dog in Rwanda</Title>
          <Description as="div">Short film, 2006</Description>
        </Container>
      </Header>
      <Content>
        <Figure 
          src='Last_Dog_Poster.jpg'
          dropShadow={true} 
          marginTop='0'
        />
        <Text content='The Last Dog in Rwanda, Jens Assur’s debut as a director and writer, announced the arrival of a new visionary film talent. The film received critical acclaim for its depiction of our problematization of war and raises important questions about the journalistic part in the portrayal of conflicts. The film relates to the genocide in Rwanda in 1994 and was shot in South Africa and Sweden. The film swept the festival circuit and was considered one of the best short films of the year.' />
        <VideoPlayer 
          vimeoID={104699527}
        />
        <Text content='The 24-year-old, news photographer, David is in Rwanda to report on the ongoing genocide. Together with the 30 years older journalist Mats, he travels through the war-torn country in an attempt to chronicle the atrocities taking place against Tutsis and Hutus.' />
        <Figure 
          src='Last_Dog_1.jpg'
          caption='Production still of Mats and David, played by Reine Brynolfsson and Jonas Karlsson'
        />
        <Award
          denomination="Best Live Action"
          competition="Palm Springs Film Festival"
        />
        <Award
          denomination="Best Film"
          competition="Australia’s Flickerfest"
        />
        <Award
          denomination="First Prize"
          competition="Tribeca Film Festival"
        />
        <Award
          denomination="Grand Prix"
          competition="Arcipelago Festival, Rome"
        />
        <Award
          denomination="Grand Prix"
          competition="Clermont-Ferrand short-film festival, France"
        />
      </Content>
    </>
  )
}

export default LastDogInRwanda