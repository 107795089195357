import React from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

  const Image = (props) => {
    const data = useStaticQuery(
      graphql`
        query ImageQuery {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  gatsbyImageData(width: 2560, quality: 90, placeholder: NONE)
                }
              }
            }
          }
        }
      `
    )

    const image = data.images.edges.find(n => {
      return n.node.relativePath.includes(props.filename)
    })
    
    if (!image) {
      return null
    }

    const imageData = getImage(image.node)

    return <GatsbyImage image={imageData} alt='' />
  }

  

// const Image = props => (
//   <StaticQuery
//     query={graphql`
//       query {
//         images: allFile {
//           edges {
//             node {
//               relativePath
//               name
//               childImageSharp {
//                 fluid(maxWidth: 1920, quality: 80) {
//                   ...GatsbyImageSharpFluid_withWebp_noBase64
//                 }
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={data => {
//       const image = data.images.edges.find(n => {
//         return n.node.relativePath.includes(props.filename)
//       })
//       if (!image) {
//         return null
//       }

//       let loading
//       if(!props.loading) {
//         loading = 'lazy'
//       } else {
//         loading = props.loading
//       }

//       const imageFluid = image.node.childImageSharp.fluid
//       return <Img alt={props.alt} fluid={imageFluid} durationFadeIn={250} fadeIn={true} loading={loading} />
//     }}
//   />
// )

export default Image