import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'


const photojournalismList = [
  {
    title: '1990',
    content: 'Appointed as a staff photographer at Expressen, the biggest daily newspaper in Scandinavia at that point.'
  },
  {
    title: '1992',
    content: 'Reporting trip to Somalia. Over the next few years, reports on dramatic global news stories such as the first democratic election in South Africa, the war in Bosnia and the genocide in Rwanda.'
  },
  {
    title: '1993',
    content: 'Wins the “News Photo of the Year” category in the annual competition held by PFK, the Press Photographs Association of Sweden, with a picture of Abdul Cader Muhammed, who was maimed by a mine in Mogadishu.'
  },
  {
    title: '1994',
    content: 'Winner of the “Press Photographer of the Year” award. The jury’s verdict: “Creativity, a feel for a great photo and rich variety, all gathered together in a perfect collection where every image conveys a gripping message”.'
  },
  {
    title: '1995',
    content: 'Winner of the main class, “Photo of the Year” The winning photo was taken at a refugee camp in Kigali, Rwanda.'
  },
  {
    title: '1996',
    content: 'Again, winner of the “Press Photographer of the Year” award in the PFK competition. Also wins “News Photo of the Year”, “Pictorial Report of the Year” and “Everyday Life in Sweden”.'
  },
  {
    title: '1997',
    content: 'Leaves Expressen and establishes himself as an independent photojournalist and artist. '
  },
]


const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const Photojournalism = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>Photojournalism</Title>
          <Description as="div">Reportages, 1990 –</Description>
        </Container>
      </Header>
      <Content>
        <Figure 
          src='Photojournalism_1.jpg'
          caption='Natal province, South Africa, 1994'
          marginTop='0'
        />
        <Figure 
          src='Photojournalism_2.jpg'
          caption='Mostar, Bosnia, 1995'
        />
        <Figure 
          src='Photojournalism_3.jpg'
          caption='Havanna, Cuba, 1991'
        />
        <Figure 
          src='Photojournalism_4.jpg'
          caption='Mogadishu, Somalia, 1992'
        />
        <Figure 
          src='Photojournalism_5.jpg'
          caption='Durban, South Africa, 1994'
        />
        <List 
          listContent={photojournalismList}
        />
      </Content>
    </>
  )
}

export default Photojournalism