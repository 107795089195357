import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import theme from '../utils/theme'


export const PillStyle = 
  css`
    display: inline-block;
    box-sizing: border-box;
    margin-left: 10px;
    padding: 10px 11px;

    color: inherit;

    font-size: 12px;
    line-height: 0.9;
    letter-spacing: 0.04em;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    border: 2px solid #000;
    border-radius: 27px;
    
    user-select: none;

    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
    
    @media (hover:hover) {
      &:hover {
        background-color: #000000;
        color: #ffffff;
        cursor: pointer;
      }
    }

    @media ${theme.breakpoints.md} { 
      margin-left: 10px;
      padding: 11px 18px;

      font-size: 16px;
      letter-spacing: 0.02em;
    }
  `

export const CommonTextStyles =
  css`
    font-family: Plantin;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2105263158;
    font-size: 23px;
    letter-spacing: -0.015em;
    word-spacing: -0.005em;
    margin: 0;

    @media ${theme.breakpoints.lg} { 
      font-size: 3.45vw;
    }
  `

export const CommonMargins =
  css`
    margin: 80px 0 160px;
  `

export const Figcaption = styled('figcaption')`
  font-family: Plantin;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 1.2105263158;
  margin: 6px 0;
  position: absolute;
  letter-spacing: 0;

  @media ${theme.breakpoints.md} { 
    font-size: 14px;
    margin: 10px 0;
  }
`

export const ContrastHelper = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  height: 200px;

  pointer-events: none;

  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 100%, 0.5) 0%,
    hsla(0, 0%, 100%, 0.494) 8.1%,
    hsla(0, 0%, 100%, 0.476) 15.5%,
    hsla(0, 0%, 100%, 0.448) 22.5%,
    hsla(0, 0%, 100%, 0.412) 29%,
    hsla(0, 0%, 100%, 0.37) 35.3%,
    hsla(0, 0%, 100%, 0.324) 41.2%,
    hsla(0, 0%, 100%, 0.275) 47.1%,
    hsla(0, 0%, 100%, 0.225) 52.9%,
    hsla(0, 0%, 100%, 0.176) 58.8%,
    hsla(0, 0%, 100%, 0.13) 64.7%,
    hsla(0, 0%, 100%, 0.088) 71%,
    hsla(0, 0%, 100%, 0.052) 77.5%,
    hsla(0, 0%, 100%, 0.024) 84.5%,
    hsla(0, 0%, 100%, 0.006) 91.9%,
    hsla(0, 0%, 100%, 0) 100%
  );

  opacity: 0.275;
`