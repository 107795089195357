import React from 'react'

const FullScreenIcon = () => (
  <>
    {/*
    <svg
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid'
      width='1em'
      height='1em'
    >
      <path d='M5 7.5H20' stroke='currentColor' style={strokeStyle} />
      <path d='M5 12.5H20' stroke='currentColor' style={strokeStyle} />
      <path d='M5 17.5H20' stroke='currentColor' style={strokeStyle} />
    </svg>
    */}

    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <g>
        <path fill="#fff" d="M175 25v150H25V25h150m25-25H0v200h200V0z"/>
      </g>
      <path fill="none" stroke="#fff" strokeWidth="25" strokeMiterlimit="10" d="M0 100h100v100"/>
    </svg>
  </>
)

export default FullScreenIcon


