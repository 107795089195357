import React, { Component } from 'react'
import Slider from 'react-slick'
// import {css} from '@emotion/react'
import styled from '@emotion/styled'

import Image from '../components/image'

import theme from '../utils/theme';

/*
import AIAGC_BOOK_1 from '../images/AIAGC_BOOK_1.jpg'
import AIAGC_BOOK_2 from '../images/AIAGC_BOOK_2.jpg'
import AIAGC_BOOK_3 from '../images/AIAGC_BOOK_3.jpg'
import AIAGC_BOOK_4 from '../images/AIAGC_BOOK_4.jpg'
*/

const Carousel = styled('div')`
  margin: 50px 0 60px;

  /* Slider */

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;

    /*
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    */
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }
  
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Arrows */
  /*
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: .25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: -25px;
  }

  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }

  .slick-prev:before {
    content: '←';
  }
  
  [dir='rtl'] .slick-prev:before {
    content: '→';
  }

  .slick-next {
    right: -25px;
  }
  
  [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
  }

  .slick-next:before {
    content: '→';
  }

  [dir='rtl'] .slick-next:before {
    content: '←';
  }
  */

  /* End of Slider */

  .slick-slide {
    div {
      outline: none;
      line-height: 0;
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 5;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .gatsby-image-wrapper img {
    transition: opacity .25s ease 0s!important;
  }
`


const ArrowContainer = styled('div')`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  display: flex;
  justify-content: center;
  
  width: 34px;
  height: 34px;

  margin: 0 15px;
  
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  transition: opacity 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  &.slick-disabled {
    opacity: 0.3;
  }

  svg {
    margin-top: -1px;
    width: 20px;

    transform: ${props =>
      props.direction == 'previous' ? 'rotate(180deg)' : 'rotate(0deg)'};
  }

  @media ${theme.breakpoints.md} { 
    width: 40px;
    height: 40px;

    margin: 0 20px;
  }
`



function ArrowButton(props) {
  const {className, style, onClick} = props;

  return (
    <ArrowContainer
      className={className}
      onClick={onClick}
      direction={props.direction}
    >
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
        <path d="M11.2451 19.1448L20.4396 9.95033L11.2451 0.755859" stroke="black"/>
        <path d="M20 10L-7.15256e-07 10" stroke="black"/>
      </svg>
    </ArrowContainer>
  );
}


export default class SimpleSlider extends Component {
  render() {
    const {images} = this.props

    const settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      //variableWidth: true,
      //lazyLoad: true,
      nextArrow: <ArrowButton direction="next" />,
      prevArrow: <ArrowButton direction="previous" />
    }

    return (
      <Carousel>
        <Slider {...settings}>
          {
            images.map((image, index) => 
              <div key={index}>
                <Image filename={ image } loading='eager' />
              </div>
            )
          }
        </Slider>
      </Carousel>
    )
  }
}