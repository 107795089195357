import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const HamburgerIcon = () => (
  <>
    {/*
    <svg
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid'
      width='1em'
      height='1em'
    >
      <path d='M5 7.5H20' stroke='currentColor' style={strokeStyle} />
      <path d='M5 12.5H20' stroke='currentColor' style={strokeStyle} />
      <path d='M5 17.5H20' stroke='currentColor' style={strokeStyle} />
    </svg>
    */}
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="1" x2="20" y2="1" strokeWidth="2"/>
      <line y1="7" x2="20" y2="7" strokeWidth="2"/>
      <line y1="13" x2="20" y2="13" strokeWidth="2"/>
    </svg>
  </>
)

export default HamburgerIcon


