import React from 'react'
import GlobalContextProvider from './src/context/globalContextProvider'
import scrollToElement from 'scroll-to-element'
// import projects from './src/projects'
import real100vh from './src/utils/real100vh'

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>
    {element}
  </GlobalContextProvider>
}

/*
 * @link https://stackoverflow.com/questions/55336831/how-to-fix-gatsby-js-link-component-retaining-scroll-position-and-not-resetting
 */
/*
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const container = document.querySelector('.site')
  const { pathname } = location
  
  // list of routes for the scroll-to-top-hook
  const sectionRoutes = [`/about/biography`, `/about/biography/`, `/about/contact`, `/about/contact/`]
  const pageRoutes = [`/`, `/about`, `/about/`, `/news`, `/news/`]
  const newsRoutes = [`/news/hiking-kebnekaise`, `/news/hiking-kebnekaise/`, `/news/jury-duty`, `/news/jury-duty/`,  `/news/studio-jens-assur-is-moving`, `/news/studio-jens-assur-is-moving/`]

  const projectRoutes = projects.map(project => project.id)

  if (sectionRoutes.indexOf(pathname) !== -1) {
    const pathnameWithoutTrailingSlash = stripTrailingSlash(pathname)
    const cleanPath = pathnameWithoutTrailingSlash.substring(pathnameWithoutTrailingSlash.lastIndexOf('/') + 1)
    container.scrollTop = 0 // goto top first

    setTimeout(() => {
      const target = document.getElementById(cleanPath)
      container.scrollTop = target.offsetTop

      if (typeof window !== 'undefined') {
        window.history.replaceState({}, '', '/about');
      }
    }, 25)

    
  } else if(newsRoutes.indexOf(pathname) !== -1) {
    const pathnameWithoutTrailingSlash = stripTrailingSlash(pathname)
    const cleanPath = pathnameWithoutTrailingSlash.substring(pathnameWithoutTrailingSlash.lastIndexOf('/') + 1)
    container.scrollTop = 0 // goto top first

    setTimeout(() => {
      const target = document.getElementById(cleanPath)
      container.scrollTop = target.offsetTop
    }, 25)
  } else if (pageRoutes.indexOf(pathname) !== -1) {
    container.scrollTop = 0;
  } else if (projectRoutes.indexOf(pathname.substr(1,)) !== -1) {
    container.scrollTop = 0 // goto top first

    setTimeout(() => {
      const target = document.getElementById(pathname.substr(1,))
      container.scrollTop = target.offsetTop
    }, 25)

  }

  return false
}



const stripTrailingSlash = (str) => {
  return str.endsWith('/') ?
    str.slice(0, -1) :
    str;
}




export const onInitialClientRender = () => {
  const projectIDs = projects.map(project => project.id)
  const newsRoutes = [`/news/hiking-kebnekaise`, `/news/hiking-kebnekaise/`, `/news/jury-duty`, `/news/jury-duty/`,  `/news/studio-jens-assur-is-moving`, `/news/studio-jens-assur-is-moving/`]

  const pathname = window.location.pathname

  if(projectIDs.indexOf(pathname.substr(1,)) !== -1) {
    setTimeout(() => {
      const target = document.getElementById(pathname.substr(1,))
      const container = document.querySelector('.site')
      
      if(container && target) {
        container.scrollTop = target.offsetTop
      }
      console.log('go to project')
    }, 1500)
  } else if(newsRoutes.indexOf(pathname) !== -1) {
    setTimeout(() => {
      const pathnameWithoutTrailingSlash = stripTrailingSlash(pathname)
      const cleanPath = pathnameWithoutTrailingSlash.substring(pathnameWithoutTrailingSlash.lastIndexOf('/') + 1)

      const container = document.querySelector('.site')
      const target = document.getElementById(cleanPath)
      
      if(container && target) {
        container.scrollTop = target.offsetTop
      }
    }, 1500)
  }
}
*/

export const onClientEntry = () => {
  real100vh()
}