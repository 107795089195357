import React from 'react'

// Components
import Text from '../components/text'
import FigureNotes from '../components/figureNotes'

const Notes = () => {
  
  return (
    <>
      <Text content='<p>For me, directing films is mainly about one thing – creating an encounter between the actor and the camera. That’s the very essence of film creation.</p><p>The character and the picture.</p><p>Together, they shape the inner and outer landscapes.</p>' />
      <FigureNotes 
        src='Korparna_Fieldnotes_1.jpg'
      />
      <Text content='<p>Ravens is restrained. It’s been made for the big screen, and it requires presence. The drama often plays out in the individual scene. That’s where the inability, the suppressed feelings and the unspoken requirements pave the way for interpretation and reflection.</p><p>That’s why the takes are long and there’s not much editing.</p>' />
      <FigureNotes 
        src='Korparna_Fieldnotes_2.jpg'
      />
      <Text content='<p>For me, making films is all about taking the audience on a journey. Intellectually, emotionally – but also to another world, another life. </p><p>That’s why I make films.</p><p>In Ravens, the world is a small farm surrounded by black, newly ploughed fields. Where the cows in need of milking always demand attention. Where the rocks have broken generations of farmers. Where the horizon is a curtain of forest.</p>' />
      <FigureNotes 
        src='Korparna_Fieldnotes_3.jpg'
      />
      <Text content='<p>There’s a black-and-white picture of photographer Sune Jonsson on a farmer reclining in a rocking chair in the kitchen somewhere in northern Sweden. He looks like my grandad, with his dark features, backcombed hair and intense gaze.</p><p>A man you don’t talk back to.</p><p>But above all, he looks like Agne, the main character in the film.</p><p>I showed our casting manager the picture. “Look at the face,” I said. “Look at the gaze. Look at the intensity. That’s what he looks like. Powerful and strong. Imagine him weak.”</p><p>“Reine Brynolfsson,” she replied.</p><p>I’ve worked with Reine Brynolfsson before, on Den sista hunden i Rwanda.</p>' />
      <FigureNotes 
        src='Korparna_Fieldnotes_4.jpg'
      />
      <Text content='<p>I have a great deal of respect for him, as a professional and as a person. He’s humble yet demanding.  And he likes to chat about the process, just as I do. Open, flexible communication is the key to achieving the best results. </p><p>We devise a diet and exercise programme so that Reine looks and moves like a farmer. He spends a year going to the gym and lifting sacks of feed. </p><p>He’s actually doing all the farming jobs you see in the film. Reine himself is chopping, harvesting and birthing the calf, to name but a few examples.</p><p>I want the tonality of my films, the artistic expression, to be consistent, to all tie in image by image, scene by scene, act by act.</p><p>That said, I want the themes and issues in my films to be contradictory. I want the audience themselves to discover what my films are all about.</p>' />
      <FigureNotes 
        src='Korparna_Fieldnotes_5.jpg'
      />
      <Text content='<p>When I made my first two films, Den sista hunden i Rwanda and Killing the chickens, to scare the monkeys, I ended up encountering an international festival audience. People often told me that there was a great deal of debate about my films, but that people were frequently unable to agree on exactly what they were all about.</p><p>And that’s exactly the way I want it to be.</p><p>As far as I’m concerned, the film is all about art, creating an encounter between the work and the audience.</p><p>That’s the very essence of film.</p><p>The work and the audience.</p>' />
    </>
  )
}

export default Notes