import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'


const utssBookArray = [
  'UTSS_Book_1.jpg',
  'UTSS_Book_2.jpg',
  'UTSS_Book_3.jpg',
  'UTSS_Book_4.jpg',
  'UTSS_Book_5.jpg',
  'UTSS_Book_6.jpg',
]


const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const ThisIsMyTimeThisIsMyLife = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>This is My Time, This is My Life</Title>
          <Description as="div">Exhibition, 2006</Description>
        </Container>
      </Header>
      <Content>
        <Text content='The exhibition “This Is My Time, This Is My Life” opened at the Modern Museum of Art in Stockholm and is Jens Assur’s take on the young people in this city in the early 2000s. Assur bases his project on the notion that people in their twenties and early thirties in Stockholm who live active social lives have more in common with young people in cities like New York, Paris, London, Madrid, Istanbul and Tokyo, than with the older generation of Stockholm or with young people living in the Swedish countryside. Assur’s explicit aesthetics are manifested in his choice of technique: the Polaroid.' />
        <FigureTile
          src='TIMT_1.jpg'
          position='centered'
          marginTop='180'
        />
        <FigureTile
          src='TIMT_2.jpg'
          position='centeredLeft'
        />
        <FigureTile
          src='TIMT_3.jpg'
          position='right'
        />
        <FigureTile
          src='TIMT_4.jpg'
          position='centered'
        />
        <FigureTile
          src='TIMT_5.jpg'
          position='centeredRight'
        />
        <FigureTile
          src='TIMT_6.jpg'
        />
        <FigureTile
          src='TIMT_7.jpg'
          position='centeredRight'
        />
        <FigureTile
          src='TIMT_8.jpg'
          position='centered'
        />
        <FigureTile
          src='TIMT_9.jpg'
          position='right'
        />
        <FigureTile
          src='TIMT_10.jpg'
        />
        <FigureTile
          src='TIMT_11.jpg'
          position='centered'
        />
        <FigureTile
          src='TIMT_12.jpg'
          position='centeredLeft'
        />
        <FigureTile
          src='TIMT_Poster.jpg'
          type='wide'
          marginTop='140'
        />
      </Content>
    </>
  )
}

export default ThisIsMyTimeThisIsMyLife