// Projects
import PrivatNatur from './privatNatur'
import AfricaIsAGreatCountry from './africaIsAGreatCountry'
import Ravens from './ravens'
import LastDogInRwanda from './lastDogInRwanda'
import KillingTheChickensToScareTheMonkeys from './killingTheChickensToScareTheMonkeys'
import Hunger from './hunger'
import UnderTheShiftingSkies from './underTheShiftingSkies'
import Photojournalism from './photojournalism'
import ThisIsMyTimeThisIsMyLife from './thisIsMyTimeThisIsMyLife'

// Field Notes
import PNNotes from '../fieldnotes/PNNotes'
import AIAGCNotes from '../fieldnotes/AIAGCNotes'
import RavensNotes from '../fieldnotes/RavensNotes'
import LastDogNotes from '../fieldnotes/LastDogNotes'
import KTCTSTNNotes from '../fieldnotes/KTCTSTNNotes'
import HungerNotes from '../fieldnotes/HungerNotes'


const projects = [
  {
    id: 'privat-natur',
    title: 'Privat Natur',
    content: <PrivatNatur />,
    fieldNotes: PNNotes,
  },
  {
    id: 'africa-is-a-great-country',
    title: 'Africa is a Great Country',
    content: <AfricaIsAGreatCountry />,
    fieldNotes: AIAGCNotes,
  },
  {
    id: 'ravens',
    title: 'Ravens',
    content: <Ravens />,
    fieldNotes: RavensNotes,
  },
  {
    id: 'the-last-dog-in-rwanda',
    title: 'The Last Dog in Rwanda',
    content: <LastDogInRwanda />,
    fieldNotes: LastDogNotes,
  },
  {
    id: 'killing-the-chickens-to-scare-the-monkeys',
    title: 'Killing the Chickens to Scare the Monkeys',
    content: <KillingTheChickensToScareTheMonkeys />,
    fieldNotes: KTCTSTNNotes,
  },
  {
    id: 'hunger',
    title: 'Hunger',
    content: <Hunger />,
    fieldNotes: HungerNotes,
  },
  {
    id: 'under-the-shifting-skies',
    title: 'Under the Shifting Skies',
    content: <UnderTheShiftingSkies />,
    fieldNotes: null,
  },
  {
    id: 'photojournalism',
    title: 'Photojournalism',
    content: <Photojournalism />,
    fieldNotes: null,
  },
  {
    id: 'this-is-my-time-this-is-my-life',
    title: 'This is My Time, This is My Life',
    content: <ThisIsMyTimeThisIsMyLife />,
    fieldNotes: null,
  }
]

export default projects