import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'


const utssBookArray = [
  'UTSS_Book_1.jpg',
  'UTSS_Book_2.jpg',
  'UTSS_Book_3.jpg',
  'UTSS_Book_4.jpg',
  'UTSS_Book_5.jpg',
  'UTSS_Book_6.jpg',
]


const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const UnderTheShiftingSkies = () => {
  return (
    <>
      <Header>
        <Container>
        <Title>Under the Shifting Skies</Title>
        <Description as="div">Exhibition and books, 2001</Description>
        </Container>
      </Header>
      <Content>
        <CarouselWithArrows 
          images={utssBookArray}
        />
        <Quote
          content='”I willingly dwell on Jens Assur’s photographs, let my eyes linger, as though each split-second of looking were a segment from a very long story. I don’t know if photos should be looked at in this way. But for me good photographic art is epic. Photo-epic. A split-second chapter in a novel for which I too am responsible, for the time preceding, perhaps also the time following: these are pictures I take seriously.”'
          source='Per Olov Enquist,<br/>
                  Writer, author and novelist'
        />
        <Figure 
          src='UTSS_1.jpg'
          caption='Gimo, Sweden, 1997'
        />
        <Text content='Under the Shifting Skies is a powerful and telling document of our times, both in Sweden and the world, and is composed of 31 thematic photo reportages presented in two volumes of 256 pages each.' />
        <Figure 
          src='UTSS_2.jpg'
          caption='Between Östersund and Gävle, Sweden, 1995'
        />
        <Text content='Jens Assur travels through Sweden, meeting Sámi, refugees, Hells Angels, neo nazis and porn stars. The experience becomes a book, in black and white. Along the way, it turns out that nearly every situation in Sweden is tied to events in other countries. That also becomes a book, this one in color. The first volume begins with a preface by the author P O Enquist; the second ends with an afterword by former Swedish Foreign Minister Jan Eliasson.' />
        <Figure 
          src='UTSS_3.jpg'
          caption='Jämtland, Sweden, 1998'
        />
        <Text content='The travelling exhibition Under the Shifting Skies opens at the Arbetets Museum in Norrköping, a town of 100,000 attracting some 80,000 visitors. The Swedish trade union confederation LO gives the book to 700 of its elected representatives at a democratic congress. Jens Assur discusses the project on countless television and radio programs. All of the major Swedish dailies write about it.' />
        <Figure 
          src='UTSS_4.jpg'
          caption='Trelleborg, Sweden, 1998'
        />
        <Figure 
          src='UTSS_5.jpg'
          caption='Ystad, Sweden, 1997'
        />
      </Content>
    </>
  )
}

export default UnderTheShiftingSkies