import React from 'react'
// import {css} from '@emotion/core'
import styled from '@emotion/styled'

import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'

import Container from '../components/container'


const Quote = styled('div')`
  margin: 45px 0 55px;
  // max-width: 920px;

  @media ${theme.breakpoints.lg} { 
    margin: 50px 0 60px;
    max-width: 83%;
  }
`

const Content = styled('div')`
  ${CommonTextStyles};
`

const Source = styled('div')`
  margin-top: 15px;
  font-size: 16px;
  letter-spacing: 0;

  @media ${theme.breakpoints.md} { 
    margin-top: 20px;
    font-size: 1.5rem
  }
`


const QuoteComponent = ({content, source}) => (
  <Container>
    <Quote>
      <Content>
        <div>{ content }</div>
        <Source dangerouslySetInnerHTML={{__html: source }} /> { /* TODO: Is this ok?  */}
      </Content>
    </Quote>
  </Container>
)

export default QuoteComponent