import React from "react";
import styled from "@emotion/styled";

import Container from "../components/container";
import Text from "../components/text";
import Quote from "../components/quote";
import Figure from "../components/figure";
import Carousel from "../components/carousel";
import CarouselWithArrows from "../components/carouselWithArrows";
import VideoPlayer from "../components/videoPlayer";
import Review from "../components/review";
import Award from "../components/award";
import List from "../components/list";
import FigureTile from "../components/figureTile";

import theme from "../utils/theme";
import { CommonTextStyles } from "../utils/styles";

const PNBookArray = ["Privat_Natur_Book_1.jpg", "Privat_Natur_Book_2.jpg"];

const Header = styled("header")`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} {
    margin-bottom: 30px;
  }
`;

const Title = styled("h2")`
  ${CommonTextStyles};
  user-select: none;
`;

const Description = styled("div")`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`;

const Content = styled("div")``;

const PrivatNatur = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>Privat Natur</Title>
          <Description as="div">Exhibition and book, 2023</Description>
        </Container>
      </Header>
      <Content>
        <Figure src="Privat_natur_34.jpg" caption="Privat Natur 34" marginTop="0" />
        <Text content="In Private Nature, Jens Assur explores the relation between Man and nature. The exhibition will be shown from October 6 to January 7, 2024, at Liljevalchs in Stockholm." />
        <Figure src="Privat_natur_75.jpg" caption="Privat Natur 75" />
        <Text content="Assur puts himself and his own family at the center of the search for the answer to big and existential questions: how nature challenges us and how it is in turn challenged from many different directions. It is about the magnificence of nature and our own smallness, our abilities, and shortcomings. What happens to us when we live far from the natural elements, and when we begin to approach them again?" />
        <Figure src="Privat_natur_97.jpg" caption="Privat Natur 97" />
        <Text content="This project encompasses deep existential questions, but also burning contemporary issues of privatization, the right of public access, and exploitation in the wake of wilderness tourism." />
        <Figure src="Privat_natur_7.jpg" caption="Privat Natur 7" />
        <Text content="In conjunction with the exhibition, the book <i>Privat Natur</i> is also published by Norstedts in collaboration with Liljevalchs. The book contains some 220 images, selected by Assur from the pictures he has been taken during the project's seven-year journey, and essays in which various writers tackle the subject." />
        <CarouselWithArrows images={PNBookArray} />
        <Figure src="Privat_natur_12.jpg" caption="Privat Natur 12" />
        <Figure src="Privat_natur_91.jpg" caption="Privat Natur 91" />
      </Content>
    </>
  );
};

export default PrivatNatur;
