import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'

import {PillStyle} from '../utils/styles'
import theme from '../utils/theme'

import { useSiteContext } from '../utils/SiteProvider'


const ProgressBar = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Indicator = styled('div')`
  ${PillStyle};
  margin-left: 0!important;

  background-color: ${theme.colors.white};
  color: ${theme.colors.orange};
  border: none;

  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.orange};
    border: none;
  }
`


const scrollProgress = (props) => {
  //console.log(props)

  const siteRef = useSiteContext();

  const [progress, setProgress] = useState(0)
  const [offset, setOffset] = useState(0)
  const indicatorRef = useRef(null)
  
  const getTotalHeight = () => {
    return Math.max(
      siteRef.current.scrollHeight,
      siteRef.current.offsetHeight,
      siteRef.current.clientHeight
    )
  }

  const scrollListener = () => {
    /*
    if (!target.current) {
      return;
    }
    */

    // const element         = target.current;
    const documentHeight      = getTotalHeight()
    const viewportHeight      = window.innerHeight
    // const windowScrollTop     = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    const windowScrollTop     = siteRef.current.scrollTop
    const totalHeight         = documentHeight - viewportHeight

    if (windowScrollTop === 0) {
      return setProgress(0)
    }

    if (windowScrollTop > totalHeight) {
      return setProgress(100)
    }

    setProgress((windowScrollTop / totalHeight) * 100)
  }
  
  useEffect(() => {
    siteRef.current.addEventListener('scroll', scrollListener)
    return () => siteRef.current.removeEventListener('scroll', scrollListener)
  }, [])

  /*
  useEffect(() => {
    window.addEventListener('resize', scrollListener)
    return () => window.removeEventListener('resize', scrollListener)
  }, [])
  */

  // is this performant?
  useEffect(() => {
    const indicator = () => {
      if(indicatorRef && indicatorRef.current) {
        const offsetWidth = Math.ceil(indicatorRef.current.offsetWidth / 2) - 1
        setOffset(offsetWidth)
      }
    }

    indicator()

    window.addEventListener('resize', indicator) 
    return () => window.removeEventListener('resize', indicator)
  }, [props.newsTitles])

  const wrapper = {
    position: 'absolute',
    right: `${offset}px`,
    left: `${offset}px`,
  }

  const style = {
    position: 'absolute',
    top: 0,
    left: `${progress}%`,
    transform: 'translateX(-50%)',
  };

  return (
    <ProgressBar className="progressbar">
      <div style={wrapper}>
        <div style={style}>
          { props.newsTitles &&
            <Indicator ref={indicatorRef}>{props.newsTitles}</Indicator>
          }
        </div>
      </div>
    </ProgressBar>
  )
}

export default scrollProgress