import React, { Component } from 'react'
import Slider from 'react-slick'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import Image from '../components/image'

import theme from '../utils/theme';
import Container from '../components/container'

import AIAGC_EXHB_1 from '../images/AIAGC_EXHB_1.jpg'
import AIAGC_EXHB_2 from '../images/AIAGC_EXHB_2.jpg'
import AIAGC_EXHB_3 from '../images/AIAGC_EXHB_3.jpg'


const Carousel = styled('div')`
  position: relative;
`

const MainSlider = styled('div')`
  /* Slider */

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;

    /*
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    */
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }
  
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  /* End of Slider */

  .slick-slide {
    width: 75vw;
    cursor: pointer;

    div {
      outline: none;
      line-height: 0;
    }

    &.slick-current {
      cursor: default;
    }
  }

  .slick-slide img {
    padding-right: 10px;
    padding-left: 15px;
    box-sizing: border-box;
  }

  .gatsby-image-wrapper img {
    transition: opacity .25s ease 0s!important;
  }

  // Breakpoints
  @media ${theme.breakpoints.md} { 
    .slick-slide {
      width: 66vw;
    }

    .slick-slide img {
      padding-right: 30px;
      padding-left: 20px;
    }
  }
`

const CommonTextStyles =
  css`
    font-family: Plantin;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 46px;
    letter-spacing: -0.015em;
    word-spacing: -0.005em;
    margin: 0;
  `

const Aside = styled('div')`
  ${CommonTextStyles};
  font-size: 10px;
  line-height: 1.2105263158;

  position: absolute;
  right: 0;
  left: 0;
  display: block;
  margin: 6px 0;

  @media ${theme.breakpoints.md} { 
    font-size: 14px;
    margin: 10px 0;
  }
`

const AsideInner = styled('div')`
  position: relative;
`

const Index = styled('div')`
  display: block;
  width: 20px;
  float: left;

  @media ${theme.breakpoints.md} { 
    width: 30px
  }
`

const CaptionSlider = styled('div')`
  /* Slider */

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;

    /*
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    */
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }
  
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  /* End of Slider */

  .slick-slide {
    div {
      outline: none;
    }
  }
`


export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      activeSlide: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      activeSlide: 0,
    });
  }

  handleClick = (e) => {
    const targetElement = e.target;
    const slideElement = targetElement.closest('.slick-slide');

    const slides = Array.from(slideElement.parentElement.children);
    const actualSlides = slides.filter(slide => !slide.classList.contains('slick-cloned'));
    const indexOfClicked = Array.from(actualSlides).indexOf(slideElement);
    
    if(indexOfClicked != this.state.activeSlide) {
      this.slider1.slickNext();
    }
  }

  render() {
    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      // focusOnSelect: true,
      // lazyLoad: true,
      variableWidth: true,
      asNavFor: this.state.nav2,
      ref: slider => (this.slider1 = slider),
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
    }

    return (
      <Carousel>
        <MainSlider>
          <Slider {...settings}>
            <div onClick={ this.handleClick }>
              <Image filename='AIAGC_EXHB_1.jpg' loading='eager' />
            </div>
            <div onClick={ this.handleClick }>
              <Image filename='AIAGC_EXHB_2.jpg' loading='eager' />
            </div>
            <div onClick={ this.handleClick }>
              <Image filename='AIAGC_EXHB_3.jpg' loading='eager' />
            </div>
          </Slider>
        </MainSlider>

        <Aside>
          <Container>
            <AsideInner>
              <Index>
                {this.state.activeSlide + 1}/3
              </Index>
              <CaptionSlider>
                <Slider
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)}
                  slidesToShow={1}
                  swipeToSlide={false}
                  focusOnSelect={false}
                  arrows={false}
                  speed={0}
                >
                  <div>
                    Liljevalchs Art Gallery
                  </div>
                  <div>
                    Liljevalchs Art Gallery
                  </div>
                  <div>
                    Liljevalchs Art Gallery
                  </div>
                </Slider>
              </CaptionSlider>
            </AsideInner>
          </Container>
        </Aside>
      </Carousel>
    )
  }
}