// @Link: https://medium.com/mtholla/create-a-transitioning-header-using-react-hooks-like-mediums-c70ed211f7c9
import { useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { useSiteContext } from './SiteProvider';

function useDocumentScrollThrottled(props, callback) {
  const siteRef = useSiteContext()
  const [, setScrollPosition] = useState(0);
  // let previousScrollTop = 0;
  const [previousScrollTop, setPreviousScrollTop] = useState(0);

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } = siteRef.current;


    setScrollPosition(previousPosition => {
      setPreviousScrollTop(previousPosition)
      return currentScrollTop;
    });

    callback({ previousScrollTop, currentScrollTop });
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 10);

  useEffect(() => {
    siteRef.current.addEventListener('scroll', handleDocumentScrollThrottled);

    return () => {
      siteRef.current.removeEventListener('scroll', handleDocumentScrollThrottled);
    }
  }, [handleDocumentScrollThrottled]);
}

export default useDocumentScrollThrottled;