import React from 'react'

// Components
import Text from '../components/text'
import FigureNotes from '../components/figureNotes'

const Notes = () => {
  
  return (
    <>
      <Text content='<p>“Killing the Chickens, to Scare the Monkeys” was based on an actual event. I saw a photo in The Independent. It was one of a number of photos that had been smuggled out from China following the Tiananmen Square massacre. It shows the execution of dissidents, but it also looks like a kind of event, judges and prosecutors, smoking and laughing.</p>' />
      <FigureNotes 
        src='KTCTSTM_Fieldnotes_1.jpg'
      />
      <Text content='<p>The film is made up of two parts, and the chronology is in reverse order. I wanted the audience to discover the sequence of events, scene by scene. The aesthetics and the storytelling of the film should open up for reflection. I consciously show only fragments of the life of Wang, the young woman, and therefore force the viewer to fill in the gaps her/himself. I am looking for a combination of a documentary style and an emotional drama.</p><p>The first part was made in a single take, 15 minutes long. We recorded it in Thailand, using mainly Chinese actors. One day for rehearsals. One day for the take; 60 people, dogs, and lorries.</p>' />
    </>
  )
}

export default Notes