// Adapter from: https://codesandbox.io/s/react-animated-modal-9rm6l

import React from 'react'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'

import theme from '../utils/theme';

import Icon from './icon'

/*
 * Styling
 */ 
const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  pointer-events: none;
`

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: #F2EDDF;
  position: absolute;
  overflow: auto;
  pointer-events: auto;
`

const CloseButton = styled('button')`
  position: sticky;
  float: right;

  appearance: none;
  background: transparent;
  padding: 0;
  width: 34px;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  top: 10px;
  right: 15px;

  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 27px;
  text-transform: uppercase;
  box-sizing: border-box;
  outline: none;
  border-width: 2px;
  border-style: solid;
  border-color: #000000;
  transition: background-color 0.1s ease-in-out, stroke 0.1s ease-in-out, opacity 0.3s ease-in-out;
  //transition: all 0.1s ease-in-out;

  opacity: ${props =>
    props.modalIsOpen ? '1' : '0' };

  svg {
    width: 14px;
    stroke: #000000;
  }

  @media (hover:hover) {
    &:hover {
      background-color: #000000;

      svg {
        stroke: #ffffff;
      }
    }
  }

  @media ${theme.breakpoints.md} {
    right: 20px;
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
    }
  }
`

const Content = styled('div')`
  margin-top: 90px;
`

const modalVariant = {
  initial: { opacity: 0 },
  modalIsOpen: { opacity: 1 },
  exit: { opacity: 0 }
};

const containerVariant = {
  initial: { top: '100%' },
  modalIsOpen: { top: '0' },
  exit: { top: '100%' }
};



const FieldNote = ({ handleClose, children, modalIsOpen }) => {

  return (
    <>
      <AnimatePresence>
        {modalIsOpen && (
          <Overlay
            initial={'initial'}
            animate={'modalIsOpen'}
            exit={'exit'}
            variants={modalVariant}
          >
            <Container variants={containerVariant}>
              <CloseButton onClick={handleClose} modalIsOpen={modalIsOpen}>
                <Icon symbol='cross' />
              </CloseButton>
              <Content>
                {children}
              </Content>
            </Container>
          </Overlay>
        )}
      </AnimatePresence>
    </>
  )
}

export default FieldNote