import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'


const AIAGCBookArray = [
  'AIAGC_BOOK_1.jpg',
  'AIAGC_BOOK_2.jpg',
  'AIAGC_BOOK_3.jpg',
  'AIAGC_BOOK_4.jpg',
]


const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const AfricaIsAGreatCountry = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>Africa is a Great Country</Title>
          <Description as="div">Exhibition and book, 2013</Description>
        </Container>
      </Header>
      <Content>
        <Figure 
          src='AIAGC_1.jpg'
          caption='Vila Olimpica, Maputo in Mozambique'
          marginTop='0'
        />
        <Text content='With his Africa is a Great Country project, Jens Assur challenged the stereotype of Africa as a homogeneous disaster zone defined by war, famine and HIV. In a one-man exhibition at the prestigious Liljevalchs art gallery in Stockholm in spring 2013, he highlighted a hyper-urbanised and dynamic Africa.' />
        <Figure 
          src='AIAGC_2.jpg'
          caption='Kicukiro Kigali, Rwanda'
        />
        <Quote
          content='“I think for many people Jens’ pictures will be truly chocking. They show a dynamic, ambitious, thriving, ordinary Africa.”'
          source='Richard Dowden<br/>
                  Executive Director, Royal African Society'
        />
        <Figure 
          src='AIAGC_3.jpg'
          caption='Aeroporto Internacional de Mavalane, Maputo, Mozambique'
        />
        <Figure 
          src='AIAGC_4.jpg'
          caption='Airport Junction Gaborone, Botswana'
        />
        <Figure 
          src='AIAGC_5.jpg'
          caption='New Lusaka Stadium, Lusaka, Zambia'
        />
        <Figure 
          src='AIAGC_6.jpg'
          caption='Kuguge Estates Kigali, Rwanda'
        />
        <Figure 
          src='AIAGC_7.jpg'
          caption='Julius Nyeres International Airport, Dar es Salaam, Tanzania'
        />
        <Text content='<i>Africa is a Great Country</i> opened at Liljevalchs on 11 April 2013 with a speech by Richard Dowden, Executive Director of the Royal African Society, and Hans Rosling, Professor of International Health and founder of the Gapminder Foundation. The guests included Nkozasana Dlamini-Zuma, Chair of the African Union Commission, authors and opinion formers Minna Salami and Tolu Ogunlesi, and a number of prominent Swedish politicians.' />
        <Carousel />
        <Quote
          content='“His fantastic pictures showed the emerging modern Africa from new and surprising angles.”'
          source='Hans Rosling<br/>
                  Professor of International Health and founder of the Gapminder Foundation'
        />
        <CarouselWithArrows 
          images={AIAGCBookArray}
        />
      </Content>
    </>
  )
}

export default AfricaIsAGreatCountry