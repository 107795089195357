import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'



const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const Ravens = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>Ravens</Title>
          <Description as="div">Feature film, 2017</Description>
        </Container>
      </Header>
      <Content>
        <Figure 
          src='Korparna_1.jpg' 
          marginTop='0'
        />
        <Text content="The film premiered in October 2017 at the Toronto International Film Festival, one of the world's most prestigious film events. It has since then been acknowledged and embraced by critics and audiences while continuing on the international film circuit, being shown in San Sebastián, Busan, London, Rome, Rotterdam, San Francisco, Tbilisi among others." />
        <VideoPlayer 
          vimeoID={465707588}
          //posterImage={Korparna3}
          posterImage='Korparna_3.jpg' 
          caption='Trailer'
        />
        <Review
          content="“Peerless – never seen anything like it”"
          stars={0}
          outlet="Svenska Dagbladet"
        />
        <Review
          content="“I love this! One of the best Swedish films of the year.”"
          stars={4}
          outlet="TV4 Nyhetsmorgon"
        />
        <Review
          content="“Movie magic!”"
          stars={4}
          outlet="Sydsvenskan"
        />
        <Figure 
          src='Korparna_2.jpg' 
          caption='Production still of Klas played by Jacob Nordström'
        />
        <Figure 
          src='Korparna_3.jpg' 
          caption='Production still of Agne played by Reine Brynolfsson'
        />
        <Figure 
          src='Korparna_4.jpg' 
          caption='Production still of Veronika and Klas played by Saga Samuelsson and Jacob Nordström'
        />
        <Figure 
          src='Korparna_5.jpg' 
          caption='Production still of Agne played by Reine Brynolfsson'
        />
        <Figure 
          src='Korparna_6.jpg' 
          caption='Production still of Agne and Gärd played by Reine Brynolfsson and Maria Heiskanen'
        />
        <Quote
          content='“Driven by a stark, sometimes terrifying beauty which suggests a kind of Arctic Georgia O’Keefe, Jens Assurs’ startlingly assured debut feature Ravens explores the trials faced by farmers desperately clinging to tradition in the face of social and technological change. Made with remarkable precision and an extraordinary eye for the right detail, Ravens has a force which suggests the best of the naturalists.”'
          source='Steve Gravestock<br/>
                  Senior Programmer, Toronto International Film Festival'
        />
        <Quote
          content='“A moving meditation on the challenges of farm life in Sweden and a disturbing psychological exposé of how it is to struggle with an unchosen vocation, Ravens by Jens Assur is a haunting, visually stunning work. As an award-winning photographer, the director brings mesmerising Ansel Adams-style perfection to each frame, the imagery and solemness of the film reminding of the iconic Ingmar Bergman.”'
          source='Catherine Sedgwick<br/>
                  31th Helsinki International Film Festival'
        />
      </Content>
    </>
  )
}

export default Ravens