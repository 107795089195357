import React from 'react'
import styled from '@emotion/styled'
import Image from '../components/image'

import theme from '../utils/theme';

import Container from '../components/container'


const Figure = styled('figure')`
  position: relative;
  line-height: 0;
  width: 100%;
  margin: 0;
  margin-top: 50px;

  .gatsby-image-wrapper img {
    transition: opacity .5s ease 0s!important;
  }

  @media ${theme.breakpoints.md} { 
    margin-top: ${props =>
      props.marginTop ? `${props.marginTop}px` : '80px' };
  }
`

const Inner = styled('div')`
  width: 185px;

  ${props => {
    if (props.type == 'wide') {
      return `
        width: 66%;
        margin-left: 16.5%;
    `
    } else if (props.position == 'centered') {
      return `
        margin-left: 50%;
        transform: translate(-50%);
    `
    } else if (props.position == 'centeredLeft') {
      return `
        margin-left: 33%;
        transform: translate(-50%);
    `
    } else if (props.position == 'centeredRight') {
      return `
        margin-left: 66%;
        transform: translate(-50%);
    `
    } else if (props.position == 'right') {
      return `
        margin-left: 100%;
        transform: translate(-100%);
    `
    } else {
      return `
        margin-left: 0;
        transform: initial;
    `
    }
  }}

  @media ${theme.breakpoints.md} { 
    ${props => {
      if (props.type == 'wide') {
        return `
          width: 66%;
      `
      } else {
        return `
          width: 370px;
      `
      }
    }}
  }
`


const FigureComponent = ({src, position, marginTop, type}) => (
  <Container>
    <Figure marginTop={marginTop}>
      <Inner position={position} type={type}>
        <Image filename={src} />
      </Inner>
    </Figure>
  </Container>
)

export default FigureComponent