import React from 'react'
import styled from '@emotion/styled'

import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'

import Container from '../components/container'

const Review = styled('div')`
  margin: 45px 0 55px;
  
  @media ${theme.breakpoints.md} { 
    margin: 50px 0 60px;
  }
`

const Stars = styled('div')`
  margin-bottom: 20px;

  span {
    margin-left: 10px;
  }

  span:first-of-type {
    margin-left: 0;
  }

  @media ${theme.breakpoints.md} { 
    margin-bottom: 25px;
  }
`

const Content = styled('div')`
  ${CommonTextStyles};
  text-align: center;
`

const Quote = styled('div')`
`

const Outlet = styled('div')`
  margin-top: 15px;
  font-size: 16px;
  letter-spacing: 0;

  @media ${theme.breakpoints.md} { 
    margin-top: 20px;
    font-size: 1.5rem
  }
`

const StarIcon = () => (
  <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9998 0L23.2655 13.1287H37.0698L25.9019 21.2426L30.1677 34.3713L18.9998 26.2574L7.83184 34.3713L12.0976 21.2426L0.929688 13.1287H14.734L18.9998 0Z" fill="black"/>
  </svg>
)


const ReviewComponent = ({content, stars, outlet}) => {
  let array = [];
  for (let i = 0; i < stars; i++) {
    array.push( <StarIcon/> )
  }

  return (
    <Container>
      <Review>
         <Content>
          { stars !== undefined && stars > 0 &&
            <Stars>
              { array.map((entry, index) => <span key={index}>{entry}</span>) }
            </Stars>
          }

          <Quote>{content}</Quote>
          <Outlet>{outlet}</Outlet>
        </Content>
      </Review>
    </Container>
  )
}

export default ReviewComponent