import React, { Fragment, useState, useEffect, useRef, useLayoutEffect } from 'react'
import useDynamicRefs from 'use-dynamic-refs';
import { useSiteContext } from '../utils/SiteProvider'


const stripTrailingSlash = (str) => {
  return str.endsWith('/') ?
    str.slice(0, -1) :
    str
}

const getSectionID = (str) => {
  //console.log(str)
  const pathWithoutTrailingSlash = stripTrailingSlash(str)
  const sectionID = pathWithoutTrailingSlash.substring(pathWithoutTrailingSlash.lastIndexOf('/') + 1)

  return sectionID
}

const Scroll = (props) => {
  const [getRef, setRef] =  useDynamicRefs();
  const siteRef = useSiteContext()
  
  useEffect(() => {
    const sectionID = props.section ? getSectionID(props.section) : null

    // console.log(sectionID)

    // console.log(sectionID)

    if(!sectionID) {
      // console.log('no id, scroll to top')
      if(siteRef && siteRef.current) {
        siteRef.current.scrollTop = 0
      }

      return // If path is bare, don't do anything
    }

    // Get ref for section
    const sectionRef = getRef(sectionID);

    // Scroll to section, if it exist
    if( (siteRef && siteRef.current) && 
        (sectionRef && sectionRef.current)
      ) {
          if(props.splash) {
            setTimeout(() => {
              siteRef.current.scrollTop = (sectionRef.current.offsetTop + 1)
            }, 1500)
          } else {
            siteRef.current.scrollTop = (sectionRef.current.offsetTop + 1)
          }
    } else {
      // console.log('scroll to top')
      if(siteRef && siteRef.current) {
        siteRef.current.scrollTop = 0
      }
    }
  }, [props.section])


  // Pass setRef to children, so it can be used to set refs so we can navigate to sections
  const childrenWithProps = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { 
      setRef
    })
  )

  return (
    <>
      {childrenWithProps}
    </>
  )
}

export default Scroll