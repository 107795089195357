import React, { useState, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'
import useDocumentScrollThrottled from './useDocumentScrollThrottled'

/*
 * Constants
 */
const UPWARDS = 'up'
const DOWNWARDS = 'down'

const UNPINNED = 'unpinned'
const PINNED = 'pinned'
const STATIC = 'static'

const NO_TRANSITION = 'none'
const NORMAL_TRANSITION = 'normal'
const PINNED_TO_STATIC = 'pinned-to-static'


/*
 * Styling
 */
const HeaderWrapper = styled.div`
  position: ${props => props.positionStickyDisabled ? 'static' : 'sticky'};
  top: ${props => props.$top}px;
  z-index: 10;
  transform: translateY(${props => props.$translateY}px);
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  ${props => props.$transition === NORMAL_TRANSITION && !props.$static
    ? 'transition: transform 0.2s ease-out;' : ''}
  ${props => props.$transition === PINNED_TO_STATIC ? css`
    animation-name: ${keyframesMoveUpFrom(props.$animateUpFrom)};
  ` : ''}
  ${props => props.$static ? 'transition: none;' : ''}
  pointer-events: none;
`

const keyframesMoveUpFrom = (from) => keyframes`
    from {
      transform: translateY(${Math.max(from, 0)}px)
    }

    to {
      transform: translateY(0)
    }
  `


/*
 * Component
 */
const Header = (props) => {
  const { 
    onStickyTopChanged, 
    height, 
    scrollHeight, 
    pinStart,
    positionStickyDisabled,
    modalIsOpen,
    toggleMenu,
    isShortcut,
    children
  } = props

  // const [shouldHideHeader, setShouldHideHeader] = useState(false);
  // const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const [mode, setMode] = useState(STATIC)
  const [transition, setTransition] = useState(NO_TRANSITION)
  const [animateUpFrom, setAnimateUpFrom] = useState(null)
 
  useEffect(() => {
    if(modalIsOpen) {
      setMode(UNPINNED)
      setTransition(NORMAL_TRANSITION)
    }
  }, [modalIsOpen])

  /*
  useEffect(() => {
    console.log(isShortcut)


  }, [isShortcut])
  */

  // const MINIMUM_SCROLL = 0;
  // const TIMEOUT_DELAY = 0;

  /*
   * If we're already static and pinStart + scrollHeight >= scrollTop, then we should stay static.
   * If we're not already static, then we should set the header static, only when pinStart >= scrollTop (regardless of
   * scrollHeight, so the header doesn't jump up, when scrolling upwards to the trigger).
   * Else we shouldn't set it static.
   * @param scrollTop the currentScrollTop position
   * @param direction the current direction
   * @returns {boolean} if we should set the header static
   */
  const shouldSetStatic = (scrollTop, direction) => {
    if (mode === STATIC || (mode === PINNED && direction === DOWNWARDS)) {
      return props.pinStart + props.scrollHeight >= scrollTop
    } else {
      return props.pinStart >= scrollTop
    }
  }

  /*
   * Determines the mode depending on the scrollTop position and the current direction
   * @param {number} scrollTop
   * @param {string} direction
   * @returns {string} the next mode of Headroom
   */
  const determineMode = (scrollTop, direction) => {
    if (shouldSetStatic(scrollTop, direction)) {
      return STATIC
    } else {
      return direction === UPWARDS ? PINNED : UNPINNED
    }
  }

  /*
   * Determines the kind of transition to apply
   */
  const determineTransition = (newMode, direction) => {
    // Handle special case: If we're pinned and going to static, we need a special transition using css animation
    if (mode == PINNED && newMode == STATIC) {
      return PINNED_TO_STATIC
    }
    // If mode is static, then no transition, because we're already in the right spot
    // (and want to change transform and top properties seamlessly)
    if (newMode === STATIC) {
      return (transition === NO_TRANSITION) ? NO_TRANSITION : PINNED_TO_STATIC
    }
    // mode is not static, transition when moving upwards or when we've lastly did the transition
    return (direction === UPWARDS || transition === NORMAL_TRANSITION) ? NORMAL_TRANSITION : NO_TRANSITION
  }

  const calcStickyTop = (mode, height, scrollHeight) => {
    return mode === PINNED ? height : height - scrollHeight
  }

  useDocumentScrollThrottled(props, callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    /*
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
    */

    let newAnimateUpFrom = null;

    const direction = previousScrollTop < currentScrollTop ? DOWNWARDS : UPWARDS
    // console.log(direction)
    
    const newMode = determineMode(currentScrollTop, direction)
    const newTransition = determineTransition(newMode, direction)
    
    if (mode === PINNED && newMode === STATIC) {
      // animation in the special case from pinned to static
      newAnimateUpFrom = currentScrollTop - pinStart
    }
    if (onStickyTopChanged && newMode !== mode && height) {
      onStickyTopChanged(calcStickyTop(newMode, height, scrollHeight))
    }

    if(isShortcut) {
      setMode(UNPINNED)
      setTransition(NO_TRANSITION)
    } else {
      setMode(newMode)
      setTransition(newTransition)
    }

    setAnimateUpFrom(newAnimateUpFrom)
  });

  const transform = mode === UNPINNED ? -scrollHeight : 0
  const ownStickyTop = mode === STATIC ? -scrollHeight : 0

  return (
    <>
      <HeaderWrapper
        $translateY={transform}
        $top={ownStickyTop}
        $transition={transition}
        $positionStickyDisabled={positionStickyDisabled}
        $static={mode === STATIC}
        $animateUpFrom={animateUpFrom}
      >
        {children}
      </HeaderWrapper>
    </>
  )
}

export default Header