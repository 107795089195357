import React from 'react'
import styled from '@emotion/styled'

import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'

import Container from '../components/container'

const Award = styled('div')`
  text-align: center;
  margin: 45px 0 55px;

  @media ${theme.breakpoints.md} { 
    margin: 50px 0 60px;
  }
`

const Content = styled('div')`
  ${CommonTextStyles};
`

const Denomination = styled('div')`
`

const Competition = styled('div')`
  font-style: italic;
`


const AwardComponent = ({denomination, competition}) => {
  return (
    <Container>
      <Award>
        <Content>
          <Denomination>{denomination}</Denomination>
          <Competition>{competition}</Competition>
        </Content>
      </Award>
    </Container>
  )
}

export default AwardComponent