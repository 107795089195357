import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const CrossIcon = () => (
  <>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 15L15 1M15 15L1 1" strokeWidth="2"/>
    </svg>
  </>
)

export default CrossIcon


