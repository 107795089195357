import React from 'react'
import styled from '@emotion/styled'

import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'

import Container from '../components/container'

const Section = styled('div')`
  margin: 45px 0 55px;

  @media ${theme.breakpoints.md} { 
    margin: 50px 0 60px;
  }
`

const List = styled('ul')`
  padding: 0;
  margin: 0;
`

const ListEntry = styled('li')`
  ${CommonTextStyles};
  list-style: none;
  display: flex;
  margin-bottom: 2rem;
`

const Title = styled('div')`
  width: 80px;

  @media ${theme.breakpoints.lg} { 
    width: 185px;
  }
`

const Content = styled('div')`
  max-width: calc(100% - 80px);

  @media ${theme.breakpoints.lg} { 
    max-width: calc(83% - 185px);
  }
`


const ListComponent = ({listContent}) => {
  // console.log(listContent)

  return (
    <Container>
      <Section>
        <List>
          {
            listContent.map((entry, index) =>
              <ListEntry key={index}>
                <Title>{entry.title}</Title>
                <Content>{entry.content}</Content>
              </ListEntry>
            )
          }
        </List>
      </Section>
    </Container>
  )
}

export default ListComponent