import React from 'react'
import styled from '@emotion/styled'

import theme from '../utils/theme';


/*
 * Styles
 */
const Container = styled('div')`
  height: 100%;
  padding: 0 15px;

  @media ${theme.breakpoints.md} { 
    padding: 0 20px
  }
`

/*
 * Component
 */
const ContainerComponent = ({children}) => (
  <Container>{children}</Container>
)

export default ContainerComponent