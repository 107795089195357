import React from 'react'
import styled from '@emotion/styled'
import Image from '../components/image'

import theme from '../utils/theme';
import {Figcaption} from '../utils/styles'

import Container from '../components/container'


const Figure = styled('figure')`
  position: relative;
  line-height: 0;
  margin: 50px 0 0 0;
  background-color: #F2EDDF;
  
  max-width: 100%;

  &:first-of-type {
    margin: 0;
  }

  .gatsby-image-wrapper {
    box-shadow: ${props =>
      props.dropShadow ? '0px 0px 6px rgba(0, 0, 0, 0.25)' : 'none'};
  }

  .gatsby-image-wrapper img {
    transition: opacity .5s ease 0s!important;
    mix-blend-mode: multiply;
  }

  @media ${theme.breakpoints.md} { 
    max-width: 741px;

    .narrow {
      max-width: 556px;
    }
  }
`

/*
const Figcaption = styled('figcaption')`
  font-family: Plantin;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0;
  position: absolute;
`
*/


const FigureNotesComponent = ({src, caption, dropShadow, blendMode}) => (
  <Container>
    <Figure dropShadow={dropShadow} blendMode={blendMode}>
      <Image filename={src} />

      {caption &&
        <Figcaption>{caption}</Figcaption>
      }
      
    </Figure>
  </Container>
)

export default FigureNotesComponent