// @Link: https://www.evandromacedo.com/active-nav-links-in-many-routes-with-gatsby-and-styled-components/

import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import projects from '../projects'
import { globalHistory as history } from '@reach/router'

const { location } = history

const validPaths = projects.map(project => project.id)


const isActive = className => ({ location }) => {
  const activeClassName = { className: `${className} active` }
  if (validPaths.includes(location.pathname.substr(1,)) || location.pathname === '/') {
    //console.log('activeClassName')
    //console.log(activeClassName)
    return activeClassName
  } else {
    //console.log('className')
    //console.log(className)
    return { className }
  }
}




const SelectedWorksLink = ({ className, children, ...props }) => {
/*
const isActive = className => ({ isCurrent, location }) => {
  const activeClassName = { className: `${className} active` }

  if (isCurrent || validPaths.includes(location.pathname.substr(1,))) {
    return activeClassName
  }

  return { className }
}
*/


  return (
    <Link getProps={isActive(className)} {...props}>
      {children}
    </Link>
  )
}

export default SelectedWorksLink