import * as React from 'react'
import styled from '@emotion/styled'

import { useSiteContext } from '../utils/SiteProvider'

const Site = styled('div')`
  position: relative;
  overflow: auto;
  height: var(--real100vh);
  transition: height 0.4s ease-in-out;
`

export default function SiteComponent({children}) {
  const siteRef = useSiteContext()

  return (
    <Site 
      ref={siteRef}
      className='site'
    >{children}</Site>
  )
}
