import React from 'react'
import styled from '@emotion/styled'


import Container from '../components/container'
import Text from '../components/text'
import Quote from '../components/quote'
import Figure from '../components/figure'
import Carousel from '../components/carousel'
import CarouselWithArrows from '../components/carouselWithArrows'
import VideoPlayer from '../components/videoPlayer'
import Review from '../components/review'
import Award from '../components/award'
import List from '../components/list'
import FigureTile from '../components/figureTile'


import theme from '../utils/theme';
import {CommonTextStyles} from '../utils/styles'



const Header = styled('header')`
  margin-bottom: 20px;

  @media ${theme.breakpoints.md} { 
    margin-bottom: 30px;
  }
`

const Title = styled('h2')`
  ${CommonTextStyles};
  user-select: none;
`

const Description = styled('div')`
  ${CommonTextStyles};
  font-style: italic;
  user-select: none;
`

const Content = styled('div')`
`


const KillingTheChickensToScareTheMonkeys = () => {
  return (
    <>
      <Header>
        <Container>
          <Title>Killing the Chickens to Scare the Monkeys</Title>
          <Description as="div">Short film, 2011</Description>
        </Container>
      </Header>
      <Content>
        <Figure 
          src='KTCTSTM_Poster.jpg'
          marginTop='0'
        />
        <Text content='With this film Assur pushed the envelope further, and just like The Last Dog in Rwanda, the film swept the international festival circuit after it premiered in Cannes.' />
        <VideoPlayer 
          vimeoID={105885095}
        />
        <Text content='<i>Killing the Chickens to Scare the Monkeys</i> is an exceptional story set in the People’s Republic of China, told from a unique perspective and rendered with a daring cinematic language.' />
        <Figure 
          src='KTCTSTM_1.jpg'
          caption='Production still'
        />
        <Award
          denomination="Premiere"
          competition="Quinzaine des Réalisateurs (Director’s Fortnight), Cannes"
        />
        <Award
          denomination="First Prize"
          competition="Nordisk Panorama, Aarhus, Denmark"
        />
        <Award
          denomination="First Prize"
          competition="Vendôme Film Festival, France"
        />
        <Award
          denomination="Special Jury Mention"
          competition="Alcine, Festival de Cine de Alcalá de Henares, Comunidad de Madrid"
        />
        <Award
          denomination="Special Jury"
          competition="Mention Prague Short Film Festival"
        />
        <Award
          denomination="Special Jury"
          competition="MentionClermont-Ferrand"
        />
        <Award
          denomination="Best film and Best fiction"
          competition="Minimalen Short Film Festival, Trondheim, Norway"
        />
      </Content>
    </>
  )
}

export default KillingTheChickensToScareTheMonkeys