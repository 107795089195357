import React from 'react'

// Components
import Text from '../components/text'
import FigureNotes from '../components/figureNotes'

const Notes = () => {
  
  return (
    <>
      <Text content='<p>I had three places to visit in São Paulo one Saturday. That meant I had to spend eight hours in the car and refuel twice. People with plenty of money preferred to travel locally by helicopter instead. Or else they bought themselves homes that they didn’t need to leave. When I was there, a shopping centre was being built that also included apartments and schools. That’s how people can live in a city without being forced to spend time in it.</p>' />
      <FigureNotes 
        src='Hunger_Fieldnotes_1.jpg'
      />
      <Text content='<p>Urban air makes you free, as promised by a German saying from the Middle Ages. Nowadays, urban air can make people feel ill and trapped. In the city, you’re imprisoned by queues of cars. And the carbon dioxide that’s pumped out into the atmosphere is pronouncing a death sentence over the world as we know it.</p><p>I implemented my “Hunger” project almost a decade ago. Even then, it was perceptible that the clock was ticking. “If there’s no action before 2012, that’s too late. What we do in the next two to three years will determine our future. This is the defining moment”, explained Rajendra Pachauri, chairman of the Intergovernmental Panel on Climate Change, in 2007.</p><p>What did we do? Was it enough? Can we carry on sitting with our engines idling in queues of cars that are growing almost as quickly and as monstrously as the cities that they obstruct?</p>' />
      <FigureNotes 
        src='Hunger_Fieldnotes_2.jpg'
      />
      <Text content='<p>Hyper-urbanisation is at its fiercest in Asia, Africa and South America. Lagos in Nigeria – to name just one example – is now forty times bigger than it was in 1950. London became the world’s first city with more than a million residents just over 200 years ago. There were eleven cities in the world with more than a million residents in 1960, and now there are more than 300 of them – and we’re seeing megacities that are home to the same number of people as medium-sized countries: 10, 20, 30 million people.</p><p>I travelled from megacity to megacity – Hamburg, São Paulo, Los Angeles, Tokyo, Shanghai, Singapore, Bombay, Dubai – before implementing my “Hunger” project. The future of humanity is being shaped there, in those cities on steroids. That was the future I wanted to depict. That was the future I wanted to try to change.</p><p>Hunger defines people. We wake up hungry. Too many of us are hungry when we fall asleep. People all over the world are living with malnutrition or famine. The hunger that prevails in industrial affluent society is a different type of hunger, the hunger of the bulimic. We eat so we can vomit, and we vomit so that we can eat even more. We produce things to throw away, and we throw things away so that we can produce more. Instead, we should release the knowledge hunger, the experience hunger, the life hunger.”</p>' />
      <FigureNotes 
        src='Hunger_Fieldnotes_3.jpg'
      />
      <Text content='<p>Homo sapiens has been exposed to nature’s whimsical cruelty throughout much of its history. But now nature seems to be defeated and oppressed. We don’t even need to accept seasonal changes any more. If the sun won’t come to us, we go to the sun instead. And it’s always strawberry season in greenhouses.</p><p>For maybe the first time in human history, huge populations – more or less all residents of the western world, that is – are free to eat when they want and whatever they want. For maybe the first time in human history, huge populations are no longer forced to do physical work. Instead, we hop in our cars and head for the gym in the hope that a nice run will release us from the prison of fat that we’ve locked ourselves into.</p><p>But suddenly, we head back through the decades – and out on the dual carriageway we’re back in the 19th century. In Bangkok, the average speed on the roads is four kilometres an hour. In Los Angeles, queues of cars trundle more quickly, but not so’s you’d notice. In London, the traffic moves as slowly as the horse-drawn carriages of old. The car, that major symbol of freedom, has become a rolling prison, a solitary confinement cell on four wheels.</p><p>Freedom has a terrible ability to devour itself.</p><p>Congested roads, blocked arteries.</p><p>But there’s a common denominator; a consumer culture that’s run amok and become its own worst enemy.</p><p>We’re living on a narrow headland and could be washed away at any time. Just how narrow? Two degrees, according to most experts. Two tiny marks on the thermometer.</p><p>Honk if you still love global warming.</p>' />
    </>
  )
}

export default Notes